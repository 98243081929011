import { ChakraProvider } from "@chakra-ui/react";
import { GoogleTagManager } from "@next/third-parties/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthGuard } from "@wpf-ui/components/auth";
import { NotFoundPage } from "@wpf-ui/components/fragments/404/NotFoundPage";
import { CurrentUserContext } from "@wpf-ui/contexts";
import { type FeatureFlag } from "@wpf-ui/gql/generated-gateway/graphql";
import { useRequestSignIn } from "@wpf-ui/hooks";
import { GlobalStyles } from "@wpf-ui/styles/global";
import { theme } from "@wpf-ui/styles/themes/extend";
import { createGraphqlGatewayClient } from "@wpf-ui/utils/gqlGateway";
import { fetchCurrentUser } from "@wpf-ui/utils/auth/fetchCurrentUser";
import App, { type AppContext } from "next/app";
import getConfig from "next/config";
import { type AppProps } from "next/dist/shared/lib/router/router";
import Head from "next/head";
import { gql, Provider } from "urql";
import "~/node_modules/@sheinc/shelikes-design-system/lib/style.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

gql(`
query currentUser {
  currentUser {
    __typename
    id
    name
    profilePhotoUrl
    isActiveUser
    isGeneralStaffUser
    isWorksAdminUser
    isWorksStaffUser
    isWorksClientUser
    isWorksShemateUser
  }
  enabledFeatures {
    __typename
    enabled
    key
  }
}
`);

const queryClient = new QueryClient();

function MyApp(props: AppProps): JSX.Element {
  /* eslint-disable @typescript-eslint/no-unsafe-argument -- ignore */
  const { Component, pageProps, currentUser, token, isForbidden, enabledFeatures } = props;
  const client = createGraphqlGatewayClient(token);
  const { isRedirecting } = useRequestSignIn(currentUser, isForbidden);

  const currentUserContext = {
    ...currentUser,
    enabledFeatures: new Set(enabledFeatures.map((v: FeatureFlag) => v.key)),
  };

  /* eslint-enable @typescript-eslint/no-unsafe-argument */
  return (
    <>
      <CurrentUserContext.Provider value={currentUserContext}>
        <Head>
          <title>SHEcreators</title>
          <meta name="robots" content="noindex,nofollow" />
        </Head>
        <QueryClientProvider client={queryClient}>
          <Provider value={client}>
            <ChakraProvider theme={theme}>
              <GlobalStyles />
              {isForbidden ? (
                <NotFoundPage />
              ) : (
                <AuthGuard isRedirecting={isRedirecting}>
                  <Component {...pageProps} />
                </AuthGuard>
              )}
            </ChakraProvider>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CurrentUserContext.Provider>
      <GoogleTagManager gtmId={getConfig().publicRuntimeConfig.GTM_CONTAINER_ID} />
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const [token, currentUser, isForbidden, enabledFeatures] = await fetchCurrentUser(appContext.ctx);
  return { ...appProps, currentUser, token, isForbidden, enabledFeatures };
};

export default MyApp;
